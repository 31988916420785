import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import BlockContent from '../components/block-content'
import Figure from '../components/figure'
import Breadcrumbs from '../components/breadcrumbs'
import KlaviyoSignupForm from '../components/klaviyo-signup-form'
import HyphenatedTitle from '../components/hyphenatedTitle'
import NewsletterSignupStyles from '../components/newsletter-signup.module.css'

const NewsletterPage = props => {
  const { data, errors } = props
  const page = data && data.page

  return (
    <>
      <SEO page={page} />
      <Breadcrumbs crumbs={[ page ]} />

      <div className={NewsletterSignupStyles.header}>
        {page && page.image && (
          <Figure node={page.image} className='w-full md:w-1/2 flex-shrink-0' aspectRatio={1} />
        )}

        <div className={NewsletterSignupStyles.content}>
          <h1 className='text-purple text-4xl md:text-5xl lg:text-4xl xl:text-5xl xxl:text-6xl leading-tight font-bold mb-8'>
            <HyphenatedTitle text={page.title} />
          </h1>
          {page && page.lead && (
            <BlockContent className="editorial mb-8" blocks={page.lead} />
          )}
          <KlaviyoSignupForm className='klaviyo' listId='SQRwTX' />
        </div>
      </div>

      {page && page.body && (
        <div className={NewsletterSignupStyles.editorial}>
          <div className='flex flex-wrap md:-mx-4'>
            <BlockContent className="editorial" blocks={page.body} />
          </div>
        </div>
      )}
    </>
  )
}

export default NewsletterPage

export const query = graphql`
  query {
    page: sanityNewsletter(_id: {regex: "/(drafts.|)newsletter/"}) {
      id
      title
      image {
        asset {
          id
          fluid(maxWidth: 1280) {
            ...GatsbySanityImageFluid
          }
        }
      }
      lead: _rawLead(resolveReferences:{maxDepth:100})
      body: _rawBody(resolveReferences:{maxDepth:100})
    }
  }
`