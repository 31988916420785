import React from 'react'
import Button from './button'

const KlaviyoSignupForm = ({ className, listId }) => {
  return (
    <div className={`${className} flex-1`}>
      <form
        action={`https://manage.kmail-lists.com/subscriptions/subscribe?a=VJieYm&g=${listId}`}
        target="_blank"
        className='klaviyo-signup-form flex flex-1 flex-col sm:flex-row mb-2'
        method="POST"
      >
        <input type="hidden" name="g" value={listId} />
        <input type="email" name="email" placeholder="Din e-post" className="border-primary bg-primary-background form-input mb-2 sm:mb-0 sm:mr-4 flex-1" required/>
        <Button
          color='green'
          extraBorder={false}
          type='submit'
        >Meld deg på</Button>
      </form>

      <div className='paragraph-4'>
        Ved å melde deg på vårt nyhetsbrev godtar du <a href="/nyhetsbrev-betingelser" target="_blank">betingelsene for nyhetsbrev</a>
      </div>
    </div>
  )
}

export default KlaviyoSignupForm
